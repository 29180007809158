import React, { useEffect, useMemo, useState } from 'react';
import WatchApi from '../apis/WatchApi';
import BitMovinPlayer from '../components/BitMovinPlayer/BitMovinPlayer';
import Poster from '../components/Poster/Poster';
import VideoPlayer from '../components/VideoPlayer/VideoPlayer';
import getParams from '../functions/getParams';

const PlayerScreen: React.FC<any> = (props) => {

    const params = getParams();

    const [video, setVideo] = useState(params);

    const watch = WatchApi.getPlayer(params.id);

    useEffect(() => {

        if (params.source === "test") {

            setVideo({
                source: "external",
                uri: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
                type: "mp4",
                title: "Test Video",
                ads: [
                    {
                        id: 'Ad',
                        position: "pre",
                        tag: {
                            type: 'vast',
                            url: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_preroll_skippable&sz=640x480&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator='
                        }
                    }
                ]
            });

        } if (params.source === "testPoster") {

            setVideo({
                source: "poster",
                poster: { fileId: "#" },
                title: "Test Poster",
            });

        } else if (params.source === "watch") {

            watch.run({
                onComplete: (response) => {

                    setVideo({
                        ...video,
                        ...response
                    });

                }
            });

        }

    }, [params.id]);

    return useMemo(() => {

        return (

            <>

                {video &&
                    <>

                        {((video.source === "pixellot" || video.source === "external") && video.uri) &&
                            <BitMovinPlayer video={video} />
                        }

                        {(video.source === "youtube" || video.source === "facebook") && video.uri &&
                            <VideoPlayer video={video} />
                        }

                        {(video.source === "poster") &&
                            <Poster video={video} />
                        }

                    </>
                }

            </>

        )

    }, [video]);

}


export default PlayerScreen;
