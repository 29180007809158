
const getParams = () => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const source = urlParams.get('source');
    const id = urlParams.get('id');
    const autoplay: any = urlParams.get('autoplay');

    let video: any = {
        source: source,
        id: id,
        autoplay: (autoplay === null || autoplay === 'true') ? true : false,
    }

    switch (source) {
        case 'youtube':

            video = {
                ...video,
                uri: `https://www.youtube.com/watch?v=${id}`,

            }
            break;

        case 'facebook':

            video = {
                ...video,
                uri: `https://www.facebook.com/facebook/videos/${id}`,
            }
            break;

        case 'pixellot':

            video = {
                ...video,
                uri: id,
            }
            break;
        default:
            break;
    }

    return video;

}


export default getParams;
